import React from 'react';
import profile from '../../../assets/img/Mask group.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Carousel from './Carousel';

const Design = () => {
  return (
    <div className='flex flex-col md:flex-row w-full min-h-full'>
      <div className='flex flex-col flex-grow w-full md:w-3/4 p-4 '>
        <Carousel />
      </div>
      <div className='w-full md:w-1/4 md:ml-2'> {/* Use w-full for small screens */}
        <div className='h-full  border border-gray-300 rounded-lg flex flex-col items-center p-4'>
          {/* Profile Photo */}
          <img
            src={profile}
            alt='Profile'
            className='w-24 h-24 rounded-full object-cover mb-4'
          />

          {/* Name */}
          <h2 className='text-xl font-semibold mb-2'>Defar Gobeze</h2>
          {/* Description */}
          <p className='text-gray-700 text-center'>
            Experience the difference with Defar. Our team will craft a unique website tailored to your exact specifications,
            ensuring a seamless and visually stunning front end along with a robust and efficient back end.
          </p>
          <div className='flex flex-col m-4'>
            <div>
              <span>
                <FontAwesomeIcon className='text-xs text-[#FD5F20] p-1 mr-1 rounded-full border border-[#FD5F20]' icon={faCheck} />
              </span>
              Prototyping
            </div>
            <div>
              <span>
                <FontAwesomeIcon className='text-sm text-[#FD5F20] p-1 mr-1 rounded-full border border-[#FD5F20]' icon={faCheck} />
              </span>
              Testing & Debugging
            </div>
            <div>
              <span>
                <FontAwesomeIcon className='text-sm text-[#FD5F20] p-1 mr-1 rounded-full border border-[#FD5F20]' icon={faCheck} />
              </span>
              Wireframing
            </div>
            <div>
              <span>
                <FontAwesomeIcon className='text-sm text-[#FD5F20] p-1 mr-1 rounded-full border border-[#FD5F20]' icon={faCheck} />
              </span>
              SEO & Analytics
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
