import React from "react";
import im1 from '../assets/various/im1.jpg'
import im2 from '../assets/various/im2.jpg'
import im3 from '../assets/various/im3.jpg'
import im4 from '../assets/various/im4.jpg'
import im5 from '../assets/various/im5.jpg'
import im6 from '../assets/various/im6.jpg'
import im7 from '../assets/various/im7.jpg'
import im8 from '../assets/various/im8.jpg'
import im9 from '../assets/various/im9.jpg'
import im10 from '../assets/various/im10.jpg'
import im11 from '../assets/various/im11.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
const Porti = () => {

   
    return (

        <div className=" relative min-h-auto">



               <div className=" py-2 px-2 text-center flex items-center justify-center">
                <div className="flex-grow border-t border-gray-900 mx-2"></div>
                <h1 className="text-4xl font-bold text-gray-900">Stories We Have Told</h1>
                <div className="flex-grow border-t border-gray-900 mx-4"></div>
            </div>
            <div className="container max-w-6xl px-5 mx-auto   my-4">
                <div className="grid gap-4 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 p-2">
                    <div className="grid gap-2 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2   rounded shadow-lg">
                        <div className="flex items-center   grid gap-1 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1">
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-28 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im1} />
                                </div>
                            </div>
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-28 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im2} />
                                </div>
                            </div>

                        </div>

                        <div className="flex items-center   grid gap-1 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1">
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-36 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im3} />
                                </div>
                            </div>
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-20 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im4} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="grid gap-2 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2   rounded shadow-lg">
                    <div className="flex items-center   grid gap-1 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1">
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-28 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im5} />
                                </div>
                            </div>
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-28 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im6} />
                                </div>
                            </div>

                        </div>

                        <div className="flex items-center   grid gap-1 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1">
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-20 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im7} />
                                </div>
                            </div>
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-36 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im8} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="grid gap-2 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2  rounded shadow-lg">
                    <div className="flex items-center   grid gap-1 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1">
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-36 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im9} />
                                </div>
                            </div>
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-20 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im10} />
                                </div>
                            </div>

                        </div>

                        <div className="flex items-center   grid gap-1 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1">
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-28 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im11} />
                                </div>
                            </div>
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-28 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im2} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="grid gap-2 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2   rounded shadow-lg">
                    <div className="flex items-center   grid gap-1 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1">
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-20 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im1} />
                                </div>
                            </div>
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-36 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im2} />
                                </div>
                            </div>

                        </div>

                        <div className="flex items-center   grid gap-1 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1">
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-28 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im1} />
                                </div>
                            </div>
                            <div >
                                <div className="shadow-xl flex items-center justify-center  w-full h-28 rounded-lg   p-1">
                                    <img className=" w-full h-full rounded-lg   object-cover " src={im2} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <FontAwesomeIcon
  className="absolute left-4 top-1/2 transform -translate-y-1/2 border-2 border-[#FD5F20] text-[#FD5F20] p-2 text-3xl font-bold rounded-full"
  icon={faChevronLeft}
/>
                {/* // <button onClick={() => alert('Previous')} className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-900 text-white p-2 rounded-full">
                //     &lt;
                // </button> */}
                {/* <button onClick={() => alert('Next')} className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-900 text-white p-2 rounded-full"> */}
                   
                 <FontAwesomeIcon className="absolute right-4 top-1/2 transform -translate-y-1/2  border-2 border-[#FD5F20] text-[#FD5F20] p-2 text-3xl font-bold rounded-full" icon={faChevronRight}/>

            </div>
        </div>

    );
}
export default Porti