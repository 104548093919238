import React from 'react'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ab from '../../assets/img/About.png'
import pstr from '../../assets/img/creative-marketing-plan.png'

const About = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Select Your Industry');

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const options = [
        'Solopreneurs',
        'Personal Training',
        'Beauty Spa',
        'Bakery & Spa',
        'Bakery & Cafe',
        'Consultancy',
        'Coaching Center',
        'Boutique Services',
        'Clinics & Medicals',
        'Other Services',
    ];
    return (
        <div className='m-8 mt-28'>
            <div className='container mx-auto '>
                <div className='flex flex-col-2'>

                    <div className='flex-1 mr-2'>
                        <  div className='flex-1  justify-between sm:space-x-8 '>

                            <div className='pt-2 md:w-full lg:w-full sm:w-full'>
                                <h1 className='font-extrabold font-times text-5xl sm:w-full sm:mb-4'>
                                    Ato Creative! Our Name Has a Story.
                                </h1>
                                <p className='font-times text-justify'>
                                    Welcome to ato Creative, a unique agency founded by three innovative minds.
                                    The term 'ato' holds significance in the Igbo language, meaning 'three',
                                    representing our founders. In Amharic, 'Ato' is a respectful term for a man.
                                    This mirrors our approach to creativity and design, emphasizing humility,
                                    respect, and collaboration. Join us at Ato Creative for projects fueled by cultural richness, collaborative effort,
                                    and boundless creativity.
                                </p>

                                <button className='bg-[#FD5F20] p-4 rounded-lg text-white mt-4 mb-6'>Watch Our Story</button>
                            </div>


                            <div className='flex flex-col gap-8 mt-8 md:flex-row md:justify-between'>
                                <div className='w-full md:w-full'>
                                    <h1 className='text-4xl md:text-5xl font-bold mb-4 underline decoration-[#FD5F20]'>
                                        We Are
                                    </h1>
                                    <ul className='text-justify'>
                                        <li className='mb-4'>
                                            <h2 className='font-bold'>Client-Centric</h2>
                                            <p className='text-gray-500'>
                                                Our clients' triumphs are our own. We tailor our services to their unique needs.
                                            </p>
                                        </li>
                                        <li className='mb-4'>
                                            <h2 className='font-bold'>Transparent & Integrative</h2>
                                            <p className='text-gray-500'>
                                                We operate with honesty and ethical behavior, fostering trust.
                                            </p>
                                        </li>
                                        <li className='mb-4'>
                                            <h2 className='font-bold'>Creative & Innovative</h2>
                                            <p className='text-gray-500'>
                                                We push boundaries with fresh ideas, driving success.
                                            </p>
                                        </li>
                                        <li className='mb-4'>
                                            <h2 className='font-bold'>Data-Driven</h2>
                                            <p className='text-gray-500'>
                                                Informed decisions from data analysis lead to optimized campaigns.
                                            </p>
                                        </li>
                                        <li className='mb-4'>
                                            <h2 className='font-bold'>Results-Oriented</h2>
                                            <p className='text-gray-500'>
                                                We focus on measurable outcomes for brand growth and revenue.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>



                        </div>
                    </div>

                    <div className='flex-1 flex-col m-4 px-2 py-2'>
                        <div className='sm:w-full lg:w-full md:full   item-justify-right'>
                            <img src={ab} className='md:pl-6 md:mt-8 md:h-80 md:w-full object-cover ' />
                        </div>
                        <div className='w-full md:w-full m-4'>
                            <h1 className='text-5xl font-bold font-times mb-4'>
                                We take time to understand your Business
                            </h1>
                            <p className='text-gray-600 mb-4 font-times text-justify '>
                                We help you find the right Niche for your business, create a Messaging that resonates with your Audience, help you structure a solution that’s appealing to your Audience
                                We then help you create different pitches to offer your solution to your Target Audience
                            </p>
                            <h1 className='text-3xl font-bold'>Act Now - Your Future Awaits!</h1>
                            <form >
                                <div className='flex flex-col-2'>
                                    <div className='w-1/2' >
                                        <div className='m-2'>
                                            <input
                                                type='text'
                                                name='name'
                                                id='name'
                                                placeholder='Name'
                                                className="w-full  border-2 p-1 border-[#FD5F20] rounded focus:border-[#FD5F20] focus:outline-none focus:ring-2 focus:ring-[#FD5F20] hover:border-[#FD5F20] active:border-[#FD5F20]"
                                            />
                                        </div>

                                        <div className='m-2'>
                                            <input
                                                type='text'
                                                name='phone'
                                                id='phone'
                                                placeholder='Phone'
                                                className="w-full border-2 p-1 border-[#FD5F20] rounded focus:border-[#FD5F20] focus:outline-none focus:ring-2 focus:ring-[#FD5F20] hover:border-[#FD5F20] active:border-[#FD5F20]"
                                            />
                                        </div>
                                    </div>
                                    <div className='w-1/2'>
                                        <div className='m-2'>
                                            <div className="relative w-full">
                                                <div


                                                    onClick={toggleDropdown}
                                                    className="w-full border-2 border-[#FD5F20] bg-white text-gray-700 rounded-lg p-2 shadow-lg hover:bg-gray-100 focus:outline-none cursor-pointer"
                                                >
                                                    {selectedOption}

                                                    <FontAwesomeIcon
                                                        className={`absolute text-[#FD5F20] right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}

                                                        icon={faChevronDown} />
                                                </div>
                                                {isOpen && (
                                                    <ul className="absolute left-0 w-full mt-1 bg-white border border-2 border-[#FD5F20] rounded-lg shadow-2xl z-50">
                                                        {options.map((option) => (
                                                            <li
                                                                key={option}
                                                                className="px-4 py-2 cursor-pointer hover:bg-[#FD5F20] hover:text-white"
                                                                onClick={() => handleOptionClick(option)}
                                                            >
                                                                {option}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>


                                        </div>
                                        <div className='m-2'>


                                            <input
                                                type='email'
                                                name='email'
                                                id='email'
                                                placeholder='Email'
                                                className="w-full  border-2 p-1 border-[#FD5F20] rounded focus:border-[#FD5F20] focus:outline-none focus:ring-2 focus:ring-[#FD5F20] hover:border-[#FD5F20] active:border-[#FD5F20]"
                                            />
                                        </div>


                                    </div></div>
                                <div className=''>
                                    <button type='submit'
                                        className='bg-[#FD5F20] rounded-lg p-2 text-white font-bold w-full '
                                    >
                                        Get Free Consultation
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                    



                </div>



            </div>
        </div >
    )
}

export default About


{/* <div className='flex flex-col gap-8 m-4 md:m-8 md:flex-row md:justify-between'>
                        <div className='w-full md:w-1/2'>
                            <h1 className='text-5xl font-bold m-4 underline decoration-[#FD5F20] '>Yes We Do </h1>
                            <ul className='m-8 text-lg font-bold justify-end flex-1 gap-2 text-right'>
                                <li className='cursor-pointer p-2'>
                                    Advertising & Branding
                                </li>
                                <li className='cursor-pointer p-2 '>
                                    Strategy Development
                                </li>
                                <li className='cursor-pointer p-2'>
                                    Digital Services
                                </li>
                                <li className='cursor-pointer p-2'>
                                    Video and Audio Production
                                </li>
                                <li className='cursor-pointer p-2'>
                                    Media Planning and Buying
                                </li>
                                <li className='cursor-pointer p-2'>
                                    Account Management
                                </li>
                            </ul>
                        </div>
                        <div className='w-full md:w-1/2 overflow-hidden'>
                            <video
                                className="md:w-full w-full  m-8"
                                controls
                                autoPlay
                                muted
                                poster={pstr} // Replace with the path to your poster image

                                src="/path/to/your/video.mp4" // Replace with the actual path to the video file
                            />

                        </div>



                    </div> */}






